<template>
  <div class="onboard-submitted" v-if="isReady">
    <div class="flex justify-between mt-12 items-start">
      <div class="w-full">
        <div class="flex justify-between items-center mb-6">
          <h3 class="text-3xl font-medium page-head-title">Application submitted</h3>
          <vs-button v-round class="only-border-btn" @click="backToView()">
            View Application
          </vs-button>
        </div>

        <p class="mt-4 top__css mb-4">
          Your reference for this application is 
          <span class="semi__bold"> {{ onboardDetail.onboardingId }}</span
          >.
        </p>
      </div>
    </div>

    <!-- {{onboardDetail}} -->
    <vs-row id="profile-page" class="mt-5">
      <!-- Business Information -->
      <vs-card class="accordin-card onboarding-completion-next-steps">
        <div slot="header">
          <h3 class="font-medium">What’s next?</h3>
        </div>
        <div>
          <p class="text-base mb-5 font-light"> Our onboarding team may contact you in the next few days to clarify a few things - keep your application reference handy for any
            communication with us.</p>
          <p class="text-base font-light">We’ll be reaching out to any beneficial owners or individual entities you’ve entered to verify their identity (details are kept
            secure and are used only for ID verification purposes). This is an important requirement for us to open your account. Please ask them to watch out for an SMS and email.
          </p>
        </div>

        <div class="mt-10">
          <div class="flex justify-center items-center">
            <div class="flex flex-col justify-center items-center for-dash">
              <span>
                <img :src="submit" alt="submit" />
              </span>
              <h6 class="text-base text-center font-light mt-2">Submitted</h6>
            </div>
            <div class="mid-dash"></div>
            <div class="flex flex-col justify-center items-center for-dash">
              <span>
                <img :src="review" alt="review" />
              </span>
              <h6 class="text-base text-center font-light mt-2">Review</h6>
            </div>
            <div class="mid-dash"></div>
            <div class="flex flex-col justify-center items-center for-dash">
              <span>
                <img :src="idComplianceCheck" alt="submit" />
              </span>
              <h6 class="text-base text-center font-light mt-2">ID & compliance <br />checks</h6>
            </div>            
            <div class="mid-dash"></div>
            <div class="flex flex-col justify-center items-center for-dash">
              <span>
                <img :src="approve" alt="approve" />
              </span>
              <h6 class="text-base text-center font-light mt-2">Approved</h6>
            </div>
          </div>
        </div>
      </vs-card>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { UploadIcon } from "vue-feather-icons";
import S3ImageGenerator from "../components/S3ImageGenerator";
const approve = require("@/assets/images/approve.svg");
const submit = require("@/assets/images/submit.svg");
const idComplianceCheck = require("@/assets/images/idCompilanceCheck.svg");
const review = require("@/assets/images/review.svg");

export default {
  name: "Submission",
  directives: {
    mask,
  },
  components: { UploadIcon, S3ImageGenerator },
  data() {
    return {
      imageBaseUrl: process.env.VUE_APP_API_URL,
      onboardingId: this.$route.params.introducerRequestedId,
      approve: approve,
      submit: submit,
      review: review,
      idComplianceCheck: idComplianceCheck,
      onboardDetail: {},
      previewImage: "",
      isBase64: false,
      preview: false,
      accountConfig: {
        businessName: "",
        abn: "",
        address: "",
        website: "",
        phoneNumber: "",
        email: "",
        file: "",
      },
      buttonDisabled: false,
      isReady: false,
    };
  },
  methods: {
    ...mapActions("onboardMerchant", [
      "fetchOnboardDetailWithId",
      "saveAccountConfig",
    ]),

    backToView() {
      this.$router.push({
        name: "reviewApplication",
        params: {
          introducerRequestedId: this.onboardingId,
        },
      });
    },
    async getDetail() {
      this.$vs.loading();
      await this.fetchOnboardDetailWithId(this.onboardingId)
        .then(async(result) => {
          if(result && result.data){
            this.onboardDetail = result.data.data;
            if (
              result.data.data.accountConfig &&
              result.data.data.accountConfig.isConfigured
            ) {
              this.accountConfig = result.data.data.accountConfig;
            } else {
              const fullAddress = result.data.data.businessOwnership && result.data.data.businessOwnership.length > 0 ? this.getRegisteredAddress(result.data.data.businessOwnership[0].registeredAddress) : "";
              this.accountConfig = {
                businessName: result.data.data.businessInfo.tradingName || "",
                abn: "",
                address: fullAddress,
                website: result.data.data.businessInfo.websiteUrl,
                email: result.data.data.businessInfo.businessEmailAddress,
                phoneNumber: result.data.data.businessInfo.businessPhoneNumber,
                file: "",
              };
              await this.saveConfig();
            }
          }
          this.isReady = true;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          
        });
    },
    async saveConfig() {
     const formData = new FormData();
     formData.append("accountConfig", JSON.stringify(this.accountConfig));
     formData.append("onboardingRequestId", this.onboardingId);
     const obj = {
       data: formData,
       config: {
         header: {
           "Content-Type": "multipart/form-data",
         },
       },
     };
     this.saveAccountConfig(obj)
       .then((response) => {
         
         if(response){
           this.accountConfig = response.data.data.accountConfig;
         }
         this.isReady = true;
       })
       .catch((err) => {
         this.$vs.loading.close();
         
       });
    },  
    getRegisteredAddress(data) {
      if (!data) return "";
      let addressData = [];
      if (data.appartmentDetail) {
        addressData.push(data.appartmentDetail);
      }
      if (data.streetAddress) {
        addressData.push(data.streetAddress);
      }
      if (data.suburb) {
        if (data.state) {
          addressData.push(data.suburb + " " + data.state);
        } else {
          addressData.push(data.suburb);
        }
      }
      if (data.country) {
        addressData.push(data.country);
      } else {
        addressData.push("Australia");
      }
      return addressData.join(", ");
    },  
  },
  mounted() {
    this.getDetail();
  },
};
</script>